import Vue from "vue"
import Home from "@/views/Home.vue"
import HomeSc from "@/views/HomeSc.vue"
import Index from "@/views/Index.vue"
import Login from "@/views/Login.vue"
import Imovel from "@/views/Imovel.vue"
import Indicar from "@/views/Indicar.vue"
// import Credpago from "@/views/Credpago.vue"
import SobreNos from "@/views/SobreNos.vue"
import TravelMap from "@/views/TravelMap.vue"
import Page404 from "@/views/Page404.vue"
import Cadastrar from "@/views/Cadastrar.vue"
import DesocupacaoImovel from "@/views/DesocupacaoImovel.vue"
import VueRouter from "vue-router"
// import SeguroFianca from "@/views/SeguroFianca.vue"
// import Chatcafe from "@/views/Chatcafe.vue"
import BuscarImoveis from "@/views/BuscarImoveis.vue"
import Campanha from "@/views/Campanha.vue"
import CampanhaVendas from "@/views/CampanhaVendas.vue"
import BuscarNasProximidades from "@/views/BuscarNasProximidades.vue"
import BuscarImoveisLocacao from "@/views/BuscarImoveisLocacao.vue"
import IndiqueEGanhe from "@/views/IndiqueEGanhe.vue"
import AnunciarImovel from "@/views/AnunciarImovel.vue"
import CashbackSocial from "@/views/CashbackSocial.vue"
import Empreendimentos from "@/views/Empreendimentos.vue"
import Empreendimento from "@/views/Empreendimento.vue"
import TermoPrivacidade from "@/views/TermoPrivacidade.vue"
import RegulamentoMinhacasaminhavida from "@/views/RegulamentoMinhacasaminhavida.vue"

import EventBus from "@/helpers/EventBus"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      mainHeader: false,
      mainFooter: false,
      mainFooter2: false,
      mainFooterPromo: true
    }
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/homesc",
    name: "homesc",
    component: HomeSc,
    meta: {
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/imovel/:codigo?/:resto?",
    name: "imovel",
    component: Imovel,
    meta: {
      navSolid: true,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/empreendimentos",
    name: "empreendimentos",
    component: Empreendimentos,
    meta: {
      navSolid: true,
      mainFooter: false,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/empreendimento",
    name: "empreendimento",
    component: Empreendimento,
    meta: {
      navSolid: true,
      mainFooter: false,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  // {
  //   path: "/chat-cafe",
  //   name: "chat-cafe",
  //   component: Chatcafe,
  //   meta: {
  //     navSolid: true,
  //     mainFooter: false,
  //     mainFooter2: false
  //   }
  // },
  {
    path: "/sobre-nos",
    name: "sobre-nos",
    component: SobreNos,
    meta: {
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/termo-de-privacidade",
    name: "termo-de-privacidade",
    component: TermoPrivacidade,
    meta: {
      mainFooter2: false
    }
  },
  {
    path: "/desocupacao-do-imovel",
    name: "desocupacao-do-imovel",
    component: DesocupacaoImovel,
    meta: {
      navSolid: true,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/mapa",
    name: "mapa",
    component: TravelMap
  },
  {
    path: "/404",
    name: "404",
    component: Page404,
    meta: {
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/anunciar-imovel/:type?",
    name: "anunciar-imovel",
    component: AnunciarImovel,
    meta: {
      navSolid: true,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/venda",
    name: "venda",
    component: BuscarImoveis,
    meta: {
      mainFooter: false,
      navSolid: true,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/campanha",
    name: "campanha",
    component: Campanha,
    meta: {
      mainFooter: false,
      navSolid: true,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/feirao-caixa",
    name: "feirao-caixa",
    component: CampanhaVendas,
    meta: {
      mainFooter: false,
      navSolid: true,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/buscar-nas-proximidades",
    name: "buscar-nas-proximidades",
    component: BuscarNasProximidades,
    meta: {
      mainFooter: false,
      navSolid: true,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/aluguel",
    name: "aluguel",
    component: BuscarImoveisLocacao,
    meta: {
      mainFooter: false,
      navSolid: true,
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/cashback-social",
    name: "cashback-social",
    component: CashbackSocial,
    meta: {
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  {
    path: "/regulamento-minhacasaminhavida",
    name: "regulamento-minhacasaminhavida",
    component: RegulamentoMinhacasaminhavida,
    meta: {
      mainFooter2: false,
      mainFooterPromo: false
    }
  },
  // {
  //   path: "/credpago/:proposta?",
  //   name: "credpago",
  //   component: Credpago,
  //   meta: {
  //     navSolid: true,
  //     footerMobile: true,
  //     mainFooter: false
  //   }
  // },
  // {
  //   path: "/seguro-fianca/:proposta?",
  //   name: "seguro-fianca",
  //   component: SeguroFianca,
  //   meta: {
  //     navSolid: true,
  //     footerMobile: true,
  //     mainFooter: false,
  //     mainFooter2: true
  //   }
  // },
  {
    path: "/entrar",
    name: "entrar",
    component: Login,
    meta: {
      mainFooter: false,
      navSolid: true,
      mainFooterPromo: false
    }
  },
  {
    path: "/cadastrar",
    name: "cadastrar",
    component: Cadastrar,
    meta: {
      mainFooter: false,
      navSolid: true,
      mainFooterPromo: false
    }
  },
  {
    path: "/indique-e-ganhe",
    name: "IndiqueEGanhe",
    component: IndiqueEGanhe,
    meta: {
      navSolid: true,
      mainFooterPromo: false
    }
  },
  {
    path: "/indicar",
    name: "Indicar",
    component: Indicar,
    meta: {
      navSolid: true,
      mainFooterPromo: false
    }
  },
  { path: "*", redirect: "/404" }
]

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASEvenda/",
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.afterEach((to, from, next) => {
  EventBus.$emit("closeMenu")
})

export default router
