<template>
  <div class="anunciar-imovel">
    <main-header></main-header>
    <c-section>
      <form-slider
        :steps="formSteps"
        title="Anunciar imóvel"
        img="directions"
        :disableImg="[5]"
        :disableNav="[1, 4, 5]"
        @advanceStep="handleAdvanceStep($event)"
      >
        <template #step-1="{advanceStep}">
          <div class="flex flex-wrap text-center">
            <div class="w-full px-2 mb-4 md:w-1/2 md:mb-0">
              <button
                class="flex flex-col items-center p-6 c-card-wrapper"
                @click="handleIsSale(1, advanceStep)"
              >
                <img
                  src="@/assets/img/home.svg"
                  class="hidden inline-block h-24 md:block"
                  aria-hidden="true"
                />
                <p class="font-medium md:pt-6">
                  <span class="text-primary-500">Vender</span>
                  um imóvel
                </p>
              </button>
            </div>
            <div class="w-full px-2 md:w-1/2">
              <!-- Pra quando O Alugadigital: href="https://alugadigital.com.br"
                <a
                  class="flex flex-col items-center p-6 c-card-wrapper"
                  href="https://alugadigital.com.br"
                >
              -->
              <button
                class="flex flex-col items-center p-6 c-card-wrapper"
                @click="handleIsSale(0, advanceStep)"
              >
                <img
                  src="@/assets/img/house.svg"
                  class="hidden inline-block h-24 md:block"
                  aria-hidden="true"
                />
                <p class="font-medium md:pt-6">
                  <span class="text-primary-500">Alugar</span>
                  um imóvel
                </p>
                <!--
                <p
                  class="flex items-center justify-center mt-2 leading-tight text-left text-gray-500"
                >
                  <font-awesome-icon icon="info-circle" class="mr-2" />
                  <small>
                    Você será redirecionado ao site da AlugaDigital.com ;)
                  </small>
                </p>
                -->
              </button>
              <!-- </a> -->
            </div>
          </div>
        </template>
        <template #step-2>
          <div class="flex flex-wrap -mx-4">
            <div class="w-full px-4 md:w-1/2">
              <c-select
                label="Tipo do imóvel"
                v-model="step_2.tipo_do_imovel"
                :options="formOptions.tipoDeImoveis"
                :invalid="$v.step_2.tipo_do_imovel.$invalid && isTouched"
              ></c-select>
            </div>
            <div class="w-full px-4 md:w-1/2">
              <c-select
                label="Condição"
                v-model="step_2.condicao"
                :options="formOptions.condicao"
                :invalid="$v.step_2.condicao.$invalid && isTouched"
              ></c-select>
            </div>
          </div>
          <div class="flex flex-wrap -mx-4">
            <div class="w-full px-4 md:w-1/2">
              <c-input
                label="Dormitórios"
                type="number"
                v-model="step_2.dormitorios"
                :invalid="$v.step_2.dormitorios.$invalid && isTouched"
                format="^\d+(\.\d{0,2})?$"
              ></c-input>
            </div>
            <div class="w-full px-4 md:w-1/2">
              <c-input
                label="Banheiros"
                type="number"
                v-model="step_2.banheiros"
                :invalid="$v.step_2.banheiros.$invalid && isTouched"
                format="^\d+(\.\d{0,2})?$"
              ></c-input>
            </div>
          </div>
          <div class="flex flex-wrap -mx-4">
            <div class="w-full px-4 md:w-1/2">
              <c-input
                label="Área privativa (em m²)"
                type="number"
                v-model="step_2.area_privativa"
                :invalid="$v.step_2.area_privativa.$invalid && isTouched"
              ></c-input>
            </div>
            <div class="w-full px-4 md:w-1/2" v-if="is_sale == 'Vender'">
              <c-input
                label="Valor de venda"
                money
                v-model="step_2.valor_venda"
                :invalid="$v.step_2.valor_venda.$invalid && isTouched"
                :disabled="step_2.valor_indefinido"
              ></c-input>
              <c-checkbox
                label="Ainda não sei"
                class="text-xs font-medium"
                v-model="step_2.valor_indefinido"
                name="step_2_valor_indefinido"
              />
            </div>
            <div class="w-full px-4 md:w-1/2" v-else>
              <c-input
                label="Valor de locação"
                money
                v-model="step_2.valor_locacao"
              ></c-input>
            </div>
            <div class="w-full px-4 md:w-1/2">
              <c-input
                label="Valor de condomínio"
                money
                v-model="step_2.valor_condominio"
              ></c-input>
            </div>
            <div class="w-full px-4 md:w-1/2">
              <c-input
                label="Valor de Iptu (anual)"
                money
                v-model="step_2.valor_iptu"
              ></c-input>
            </div>
          </div>
        </template>

        <template #step-3>
          <div class="flex flex-wrap -mx-4">
            <div class="w-full px-4 md:w-1/2">
              <c-input
                label="CEP"
                v-model="step_3.cep"
                :invalid="$v.step_3.cep.$invalid && isTouched"
                mask="cep"
              ></c-input>
            </div>
            <div class="w-full px-4 md:w-1/2">
              <c-select
                label="Estado"
                :options="states"
                v-model="step_3.estado"
                :invalid="$v.step_3.estado.$invalid && isTouched"
              ></c-select>
            </div>
          </div>
          <div class="flex flex-wrap -mx-4">
            <div class="w-full px-4 md:w-1/2">
              <c-input
                label="Cidade"
                v-model="step_3.cidade"
                :invalid="$v.step_3.cidade.$invalid && isTouched"
              ></c-input>
            </div>
            <div class="w-full px-4 md:w-1/2">
              <c-input
                label="Bairro"
                placeholder="Seu bairro"
                v-model="step_3.bairro"
                :invalid="$v.step_3.bairro.$invalid && isTouched"
              ></c-input>
            </div>
          </div>
          <div class="flex flex-wrap -mx-4">
            <div class="w-full px-4 md:w-1/2">
              <c-input
                class="w-full"
                label="Logradouro"
                placeholder="Rua, avenida, complemento"
                v-model="step_3.logradouro"
                :invalid="$v.step_3.logradouro.$invalid && isTouched"
              ></c-input>
            </div>
            <div class="w-full px-4 md:w-1/2">
              <c-input
                class="w-full"
                label="Número"
                placeholder="Número"
                v-model="step_3.numero"
                :invalid="$v.step_3.numero.$invalid && isTouched"
              ></c-input>
            </div>
          </div>
        </template>

        <template #step-4="{advanceStep}">
          <div class="flex">
            <c-input
              class="w-full"
              label="Nome"
              placeholder="Seu nome completo"
              v-model="step_4.nome"
              :invalid="$v.step_4.nome.$invalid && isTouched"
            ></c-input>
          </div>
          <div class="flex">
            <c-input
              class="w-full"
              label="E-mail"
              type="email"
              v-model="step_4.email"
              :invalid="$v.step_4.email.$invalid && isTouched"
              mask="email"
              placeholder="Seu email"
            ></c-input>
          </div>
          <div class="flex">
            <c-input
              class="w-full"
              label="Telefone"
              v-model="step_4.telefone"
              :invalid="$v.step_4.telefone.$invalid && isTouched"
              mask="tel"
            ></c-input>
          </div>
          <c-button class="w-full primary md:w-auto" @click="enviarContato(); handleIsSale(1, advanceStep)">
            Enviar
          </c-button>
        </template>

        <template #step-5>
          <div class="text-center">
            <img
              src="@/assets/img/confirmed.svg"
              alt="Tudo certo!"
              aria-hidden="true"
              class="w-40 m-auto mb-4"
            />

            <p>
              Agora é só esperar nossos especialistas de
              <br />
              vendas entrarem em contato com você.
              <br />
              Agradecemos a confiança!
            </p>
            <br />
            <router-link class="w-full c-button primary" to="/">
              Voltar à página Inicial
            </router-link>
          </div>
        </template>
      </form-slider>
    </c-section>
  </div>
</template>

<script>
import CCheckbox from "../components/UI/CCheckbox.vue"
import FormSlider from "../components/FormSlider.vue"
import CSelect from "../components/UI/CSelect.vue"
import CInput from "../components/UI/CInput.vue"
import CSection from "../components/UI/CSection.vue"
import MainHeader from "../components/MainHeader.vue"
import FormOptionsMixin from "../mixins/FormOptionsMixin"
import { required } from "vuelidate/lib/validators"
import EstadoCidadeMixin from "../mixins/EstadoCidadeMixin"
import EventBus from "../helpers/EventBus"
import axios from "axios"
import CButton from "../components/UI/CButton.vue"

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Anunciar Imóvel",
    meta: [
      { name: "description", content: "Café Inteligência Imobiliária, imóveis santa maria, Imobiliarias santa maria, Anunciar Imóvel" }
    ]
  },
  components: {
    MainHeader,
    CSection,
    CInput,
    CSelect,
    FormSlider,
    CCheckbox,
    CButton
  },
  mixins: [EstadoCidadeMixin, FormOptionsMixin],
  data() {
    return {
      is_sale: undefined,
      step_2: {
        tipo_do_imovel: "",
        condicao: "",
        dormitorios: "0",
        banheiros: "0",
        area_privativa: "0",
        valor_venda: 0,
        valor_locacao: 0,
        valor_indefinido: false,
        valor_condominio: 0,
        valor_iptu: 0
      },
      step_3: {
        cidade: "",
        estado: "",
        logradouro: "",
        bairro: "",
        cep: "",
        numero: ""
      },
      step_4: {
        nome: "",
        email: "",
        telefone: ""
      },
      isTouched: false
    }
  },
  computed: {
    formSteps() {
      return [
        "Qual a finalidade do seu anúncio?",
        "Características do seu imóvel",
        "Onde fica o seu imóvel",
        "Como podemos entrar em contato?",
        "Tudo certo!"
      ]
    },
    formTitle() {
      return this.is_sale ? this.is_sale : "Anunciar"
    }
  },
  mounted() {
    if (this.$route.params.type) {
      this.is_sale = 1
      EventBus.$emit("globalAdvanceStep")
    }
  },
  methods: {
    handleIsSale(saleType, advanceStep) {
      this.is_sale = saleType ? "Vender" : "Alugar"
      advanceStep()
    },
    handleAdvanceStep({ currentStep, advanceStep }) {
      if (this.$v[`step_${currentStep}`].$invalid) {
        this.isTouched = true
        return false
      }

      advanceStep()
    },
    enviarContato() {
      let currentObj = this
      axios.post(
        "/api/enviarcontato.php", JSON.stringify({
          fonte: window.location.href,
          tipo_do_imovel: this.step_2.tipo_do_imovel,
          condicao: this.step_2.condicao,
          dormitorios: this.step_2.dormitorios,
          banheiros: this.step_2.banheiros,
          area_privativa: this.step_2.area_privativa,
          valor_venda: this.step_2.valor_venda,
          valor_locacao: this.step_2.valor_locacao,
          valor_indefinido: this.step_2.valor_indefinido,
          valor_condominio: this.step_2.valor_condominio,
          valor_iptu: this.step_2.valor_iptu,
          cidade: this.step_3.cidade,
          estado: this.step_3.estado,
          logradouro: this.step_3.logradouro,
          bairro: this.step_3.bairro,
          cep: this.step_3.cep,
          nome: this.step_4.nome,
          email: this.step_4.email,
          telefone: this.step_4.telefone
        }))
        .then(function (response) {
          // alert("Recebemos sua mensagem, em breve retornaremos")
          currentObj.output = response.data
          // advanceStep()
        })
        .catch(function (error) {
          currentObj.output = error
        })
    }
  },
  validations: {
    is_sale: { required },
    step_2: {
      tipo_do_imovel: { required },
      condicao: { required },
      dormitorios: { required },
      banheiros: { required },
      area_privativa: { required },
      valor_venda: { required }
    },
    step_3: {
      cidade: { required },
      estado: { required },
      logradouro: { required },
      bairro: { required },
      cep: { required },
      numero: { required }
    },
    step_4: {
      nome: { required },
      email: { required },
      telefone: { required }
    }
  }
}
</script>

<style lang="postcss">
.anunciar-imovel {
  background: url("../assets/img/blob_bg.png") no-repeat right top,
    url("../assets/img/bubble_bg.png") no-repeat 5% 95%;
}
</style>
